import React from 'react';
import { ImageWrapper } from 'components/common/ImageWrapper';
import { createStyles } from './styles';
import LeadsImg from '../../../assets/images/platform/leads.png';
import TrackingStatusesImg from '../../../assets/images/platform/tracking-statuses.png';
import EnrollImg from '../../../assets/images/platform/enroll.png';
import RenewalImg from '../../../assets/images/platform/renewal.png';
import InsightsImg from '../../../assets/images/platform/insights.png';

const Platform = (): JSX.Element => {
  const classes = createStyles();

  const PLATFORM_DEMO_LIST = [
    {
      title: 'Prospect More Leads',
      description:
        'Web storefronts stocked with your products so that new leads can instantly browse products and be routed to agents to close the sale.',
      icon: <ImageWrapper imageURL={LeadsImg} imageAlt="Prospect More Leads" className={classes.imageWrapper} />,
    },
    {
      title: 'Quote Faster And Track Status',
      description:
        'Quickly send multi-line proposals to prospects and track status. Get notified when the proposal has been viewed or approved. Save your most popular proposals for quick re-use on new prospects.',
      icon: (
        <ImageWrapper
          imageURL={TrackingStatusesImg}
          imageAlt="Quote Faster And Track Status"
          className={classes.imageWrapper}
        />
      ),
    },
    {
      title: 'Enroll With Ease',
      description:
        'Assign closed sales to your account team to monitor enrollment status and invite groups to self-enroll online. Batch enrollment tools to complete elections on behalf of employees with just a few clicks. EDI enrollment feeds and auto-filled PDFs so that clean and accurate data is delivered to carriers.',
      icon: <ImageWrapper imageURL={EnrollImg} imageAlt="Stay Competitive" className={classes.imageWrapper} />,
    },
    {
      title: 'Renew And Cross-Sell',
      description:
        'Consolidated dashboard to work on upcoming renewals. Quickly see cross-sell and up-sell opportunities. Send renewal proposals with multiple plan options. Easily enroll groups in new lines, make changes to existing benefits, and submit renewals to the carrier.',
      icon: <ImageWrapper imageURL={RenewalImg} imageAlt="Renew And Cross-Sell" className={classes.imageWrapper} />,
    },
    {
      title: 'Measure With Insight',
      description:
        'Drive strategic planning with channel analytics that only a digital platform can provide. Understand consumer preferences, product appeal, and marketing program impact. Improve conversions and exceed sales targets.',
      icon: <ImageWrapper imageURL={InsightsImg} imageAlt="Measure With Insight" className={classes.imageWrapper} />,
    },
  ];

  const PlatformDemoItems = PLATFORM_DEMO_LIST.map(({ title, description, icon }) => (
    <li className={classes.platformDemoItem} key={title}>
      {icon}
      <div className={classes.platformDemoInfo}>
        <p className={classes.platformDemoItemTitle}>{title}</p>
        <p className={classes.platformDemoItemDescription}>{description}</p>
      </div>
    </li>
  ));
  return (
    <div className={classes.platformDemoSection}>
      <h3 className={classes.platformDemoTitle}>OUR PLATFORM</h3>
      <ul className={classes.platformDemoList}>{PlatformDemoItems}</ul>
    </div>
  );
};

export default Platform;
