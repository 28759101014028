import React from 'react';
import PrivacyPolicy from 'components/privacy-policy';
import Header from 'components/common/Header';

const PrivacyPolicyPage = (): JSX.Element => {
  return (
    <div>
      <Header showMenu={false} />
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicyPage;
