import React from 'react';
import ScrollToTop from 'global/hooks/ScrollToTop';
import HomePage from 'pages/HomePage';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AboutUsPage from 'pages/AboutUsPage';
import ContactsPage from 'pages/ContactsPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import TermsOfUsePage from 'pages/TermOfUsePage';

const App: React.FC = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/about-us" component={AboutUsPage} />
            <Route exact path="/contacts" component={ContactsPage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route exact path="/terms-of-use" component={TermsOfUsePage} />
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route exact path="*">
              <Redirect to="/error-404" />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export default App;
