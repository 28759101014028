import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types/global';

const styles: ComponentStylesProps<string> = {
  header: { backgroundColor: colors.neutral.white },
  desktopHeaderWrapper: {
    margin: '0 auto',
    display: 'flex',
    padding: '2rem 10rem',
    maxWidth: '170rem',
    alignItems: 'center',
    zIndex: 11,
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.16) 4px 7px 4px -4px',
    justifyContent: 'space-between',
  },
  headerButtons: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    '& button': {
      minWidth: '16rem',
    },
  },
  links: {
    display: 'flex',
    gap: '2rem',
    listStyle: 'none',
    alignItems: 'center',
    paddingLeft: 0,
  },
  link: {
    textDecoration: 'none',
    color: colors.neutral[900],
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    letterSpacing: '0.02em',
    position: 'relative',
  },
  activeLink: {
    color: colors.orange[600],
    '&::after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '0.2rem',
      backgroundColor: colors.orange[600],
      left: 0,
      right: 0,
      bottom: '-1rem',
    },
  },
  mobileActiveLink: { backgroundColor: colors.orange[100] },
  imageWrapper: {
    width: '20rem',
  },
  mobileScreenLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileScreenLogo: {
    width: '35rem',
    maxHeight: '5.2rem',
    [breakpoints.lg.down]: {
      width: '70%',
      margin: '0 auto',
    },
  },
  mobileHeaderWrapper: {
    backgroundColor: colors.neutral.white,
    color: colors.neutral[700],
    boxShadow: '4px 7px 4px -4px rgba(0, 0, 0, 0.16)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2.4rem',
    boxSizing: 'border-box',
    height: '6.4rem',
    position: 'fixed',
    zIndex: 1,
    width: '100vw',
    top: 0,
  },
  // Desktop media
  '@media(max-width:980px)': {
    // MAKE IT ONLY AS FUNCTION, otherwise styles will not be applied
    desktopHeaderWrapper: () => ({
      display: 'none',
    }),
  },

  // Desktop media
  '@media(max-width:1200px)': {
    // MAKE IT ONLY AS FUNCTION, otherwise styles will not be applied
    desktopHeaderWrapper: () => ({
      padding: '2rem',
    }),
  },

  // Mobile media
  '@media(min-width:981px)': {
    // MAKE IT ONLY AS FUNCTION, otherwise styles will not be applied
    mobileHeaderWrapper: () => ({
      display: 'none',
    }),
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
