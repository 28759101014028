import React from 'react';
import { colors } from '@insurancemenu/imenu-components-lib';
import { Mail } from '@insurancemenu/imenu-components-lib/dist/icons';
import Address from 'assets/images/contacts/Address';
import Phone from 'assets/images/contacts/Phone';

import { createStyles } from './styles';

const Contacts = (): JSX.Element => {
  const classes = createStyles();
  return (
    <div>
      <div className={classes.contactsTitleWrapper}>
        <h1 className={classes.contactsTitle}>Contact us</h1>
      </div>
      <div className={classes.contactsInfoWrapper}>
        <h3 className={classes.contactsSecondaryTitle}>Connect with our team</h3>
        <p className={classes.contactsDescription}>
          Our team is always happy to assist in any way that we can. Whether you need assistance with using the product,
          are interested in buying the product, or are curious about any of our resources that are available, our team
          is prepared to help you.
        </p>
      </div>
      <ul className={classes.contactsList}>
        <li className={classes.contactsItem}>
          <Address height="5rem" color={colors.blue[600]} />
          <p className={classes.contactsItemTitle}>Address</p>
          <p className={classes.contactsItemDescription}>45 Dan Road Suite 26 Canton, MA 02021</p>
        </li>
        <li className={classes.contactsItem}>
          <Phone height="5rem" color={colors.blue[600]} />
          <p className={classes.contactsItemTitle}>Phone</p>
          <a className={classes.contactsItemDescription} href="tel:6176004720">
            (617) 600-4720
          </a>
        </li>
        <li className={classes.contactsItem}>
          <Mail height="5rem" color={colors.blue[600]} />
          <p className={classes.contactsItemTitle}>Email</p>
          <a className={classes.contactsItemDescription} href="mailto:info@insurancemenu.com">
            info@insurancemenu.com
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Contacts;
