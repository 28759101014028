import { colors } from '@insurancemenu/imenu-components-lib';
import { createUseStyles } from 'react-jss';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import BgImage from '../../assets/images/contacts/contact-banner.png';

const styles: ComponentStylesProps<string> = {
  contactsTitleWrapper: {
    padding: '13.5rem 2rem',
    background: `url(${BgImage}) no-repeat center center/cover`,
    '@media(max-width: 980px)': {
      padding: '14.4rem 2rem 8rem',
    },
  },
  contactsInfoWrapper: {
    gap: '2rem',
    margin: '0 auto',
    display: 'flex',
    padding: '8rem 10rem 0',
    maxWidth: '72%',
    flexDirection: 'column',
    textAlign: 'center',
    [breakpoints.md.down]: {
      padding: '4rem',
      maxWidth: '100%',
    },
    [breakpoints.sm.down]: {
      padding: '2rem',
    },
  },
  contactsTitle: {
    fontSize: '4rem',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.neutral.white,
  },
  contactsSecondaryTitle: {
    fontSize: '2.4rem',
    lineHeight: '3.4rem',
    margin: 0,
  },
  contactsDescription: {
    margin: 0,
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    [breakpoints.md.down]: {
      textAlign: 'left',
    },
  },
  contactsList: {
    padding: '8rem 10rem',
    gap: '3%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 0,
    [breakpoints.lg.down]: {
      justifyContent: 'space-between',
    },
    [breakpoints.md.down]: {
      rowGap: '5rem',
      flexDirection: 'column',
      alignItems: 'center',
    },
    [breakpoints.sm.down]: {
      padding: '2rem',
    },
  },
  contactsItem: {
    width: '25%',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.md.down]: {
      width: '100%',
    },
  },
  contactsItemTitle: {
    fontSize: '2.4rem',
    margin: 0,
    marginTop: '1rem',
    lineHeight: '2.8rem',
    fontWeight: 700,
  },
  contactsItemDescription: {
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    textAlign: 'center',
    margin: 0,
    textDecoration: 'none',
    color: colors.neutral[900],
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
