import React from 'react';
import clsx from 'clsx';
import { OptionsAlignmentTypes } from '@insurancemenu/imenu-components-lib/dist/types';
import { AccountCircle, ExitToApp, Help, Mail, Menu, UserGroup } from '@insurancemenu/imenu-components-lib/dist/icons';
import { Button, Dropdown } from '@insurancemenu/imenu-components-lib/dist/components';
import { createStyles } from './styles';
import { DropdownButtonElement, DropdownLinkElement } from '../MobileDropdownElement';
import { LINKS } from '..';

const MobileDropdown = (): JSX.Element => {
  const classes = createStyles();

  const HeaderLinks = [
    {
      value: '',
      renderFunc: (): JSX.Element => (
        <DropdownLinkElement text="About Us" href="/about-us" icon={<UserGroup />} key="about-us" />
      ),
    },
    {
      value: '',
      renderFunc: (): JSX.Element => (
        <DropdownLinkElement text="Help Center" href={LINKS.HELP_CENTER} icon={<Help />} key="help-center" external />
      ),
    },
    {
      value: '',
      renderFunc: (): JSX.Element => (
        <DropdownLinkElement text="Contacts" href="/contacts" icon={<Mail />} key="contacts" />
      ),
    },
  ];

  const HeaderButtons = [
    {
      value: 'scheduleDemo',
      renderFunc: (): JSX.Element => (
        <DropdownButtonElement
          text="Schedule a demo"
          icon={<AccountCircle />}
          key="schedule-demo"
          onClick={(): void => {
            window.location.href = LINKS.SCHEDULE_DEMO;
          }}
        />
      ),
    },
    {
      value: 'login',
      renderFunc: (): JSX.Element => (
        <DropdownButtonElement
          text="Login"
          icon={<ExitToApp />}
          key="login"
          onClick={(): void => {
            window.location.href = LINKS.LOGIN;
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Dropdown
        name="mobileNavDropdown"
        options={[...HeaderLinks, ...HeaderButtons]}
        optionsAlignment={OptionsAlignmentTypes.BOTTOM_LEFT}
        optionsWidth="20rem"
        className={classes.navDropdown}
        closedElement={
          <Button type="icon" className={clsx(classes.mobileDropdownButton, classes.mobileDropdownButtonClosed)}>
            <Menu height="3.2rem" width="3.2rem" />
          </Button>
        }
        openedElement={
          <Button type="icon" className={clsx(classes.mobileDropdownButton, classes.mobileDropdownButtonOpened)}>
            <Menu height="3.2rem" width="3.2rem" />
          </Button>
        }
      />
    </>
  );
};

export default MobileDropdown;
