import { colors } from '@insurancemenu/imenu-components-lib';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { createUseStyles } from 'react-jss';

const styles: ComponentStylesProps<string> = {
  featuresSection: {
    padding: '10rem',
    color: colors.neutral.white,
    background: '#152437',
    [breakpoints.md.down]: {
      padding: '4rem',
    },
    '@media(max-width: 522px)': {
      padding: '4rem 2rem',
    },
  },
  featuresInfo: {
    gap: '1rem',
    display: 'flex',
    maxWidth: '50%',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto 8rem',
    textAlign: 'center',
    [breakpoints.lg.down]: {
      maxWidth: '100%',
      margin: '0 auto 4rem',
    },
  },
  featuresTitle: {
    margin: 0,
    fontSize: '3rem',
    lineHeight: '4.2rem',
  },
  featuresSubtitle: {
    margin: 0,
    fontSize: '1.8rem',
    textAlign: 'center',
    lineHeight: '2.8rem',
  },
  featuresList: {
    gap: '3%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
    [breakpoints.xlg.down]: {
      gap: '5rem',
      justifyContent: 'center',
    },
  },
  featureItem: {
    width: '17%',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '18rem',
    [breakpoints.sm.down]: {
      width: '100%',
    },
  },
  featureItemTitle: {
    fontSize: '2.4rem',
    margin: 0,
    marginTop: '1rem',
    lineHeight: '2.8rem',
    fontWeight: 700,
  },
  featureItemDescription: {
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    textAlign: 'center',
    margin: 0,
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
