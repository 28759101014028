import React from 'react';
import { IconUsageProps } from '@insurancemenu/imenu-components-lib/dist/types/Icon';
import createSVGIcon from '@insurancemenu/imenu-components-lib/dist/icons/createSVGIcon';

const Measure = (props: IconUsageProps): JSX.Element => {
  const paths = (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M500 400c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v324h452zm-356-60v-72c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v72c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12zm96 0V140c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v200c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12zm96 0V204c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v136c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12zm96 0V108c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v232c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12z"
    />
  );

  return createSVGIcon({
    children: paths,
    viewBox: '0 0 512 512',
    ...props,
  });
};

export default Measure;
