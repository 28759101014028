import React from 'react';
import { Button } from '@insurancemenu/imenu-components-lib';
import { LINKS } from 'components/common/Header';
import { createStyles } from './styles';

const CTABlock = (): JSX.Element => {
  const classes = createStyles();

  return (
    <div className={classes.ctaSection}>
      <h3 className={classes.ctaTitle}>EASY AND SIMPLE TO USE FOR ALL LEVELS.</h3>
      <p className={classes.ctaSubtitle}>
        Leverage the intuitive software that saves you time and will help grow your business.
      </p>
      <Button
        color="primary"
        size="large"
        onClick={(): void => {
          window.open(LINKS.SCHEDULE_DEMO, '_blank');
        }}
      >
        Schedule a demo
      </Button>
    </div>
  );
};

export default CTABlock;
