import React from 'react';
import IMLogo from '../../../assets/images/general/insuranceMenuLogo.svg';
import { createStyles } from './styles';
import { ImageWrapper } from '../ImageWrapper';
import { LINKS } from '../Header';

const Footer = (): JSX.Element => {
  const classes = createStyles();
  const currentYear = new Date().getFullYear();
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        <ImageWrapper imageURL={IMLogo} imageAlt="InsuranceMenu logo" className={classes.imageWrapper} />
        <ul className={classes.links}>
          <li className={classes.linkItem}>
            <a href="/about-us">About us</a>
          </li>
          <li className={classes.linkItem}>
            <a href={`${LINKS.HELP_CENTER}`}>Help Center</a>
          </li>
          <li className={classes.linkItem}>
            <a href={`${LINKS.SUPPORT}`} target="_blank" rel="noopener noreferrer">
              Contact Support
            </a>
          </li>
          <li className={classes.linkItem}>
            <a href="/contacts">Contacts</a>
          </li>
        </ul>
        <ul className={classes.links}>
          <li className={classes.linkItem}>
            <a href="https://home.insurancemenu.com/privacy-policy" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
          </li>
          <li className={classes.linkItem}>
            <a href="https://home.insurancemenu.com/terms-of-use" rel="noreferrer" target="_blank">
              Terms of Use
            </a>
          </li>
        </ul>
      </div>
      <p className={classes.copyright}>{`Copyright ® ${currentYear} InsuranceMenu Inc. All rights reserved.`}</p>
    </footer>
  );
};

export default Footer;
