import React from 'react';
import clsx from 'clsx';
import { Button } from '@insurancemenu/imenu-components-lib';
import { Link } from 'react-router-dom';
import { createStyles } from '../MobileDropdown/styles';

interface DropdownLinkElementProps {
  href: string;
  text: string;
  icon: JSX.Element;
  className?: string;
  external?: boolean;
}
interface DropdownButtonElementProps {
  onClick: () => void;
  text: string;
  icon: JSX.Element;
}

export const DropdownLinkElement: React.FC<DropdownLinkElementProps> = ({
  href,
  text,
  icon,
  external = false,
  className = '',
}) => {
  const classes = createStyles();
  const content = (
    <>
      <span className={classes.mobileDropdownIcon}>{icon}</span>
      <span className={classes.mobileDropdownLinkText}>{text}</span>
    </>
  );

  return (
    <li className={clsx(classes.mobileDropdownOption, className)}>
      {external ? (
        <a href={href} className={classes.mobileDropdownLink}>
          {content}
        </a>
      ) : (
        <Link className={classes.mobileDropdownLink} to={href}>
          {content}
        </Link>
      )}
    </li>
  );
};

export const DropdownButtonElement: React.FC<DropdownButtonElementProps> = ({ text, onClick, icon }) => {
  const classes = createStyles();

  return (
    <li className={classes.mobileDropdownOption}>
      <Button type="text" leftIconProp={icon} className={classes.mobileDropdownLink} onClick={onClick}>
        {text}
      </Button>
    </li>
  );
};
