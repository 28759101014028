import { colors } from '@insurancemenu/imenu-components-lib';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { createUseStyles } from 'react-jss';

const styles: ComponentStylesProps<string> = {
  advantagesSection: {
    padding: '8rem 10rem',
    background: colors.neutral[100],
    [breakpoints.md.down]: {
      padding: '4rem',
    },
  },
  advantagesTitle: {
    margin: '0 0 5rem',
    fontSize: '3rem',
    textAlign: 'center',
    lineHeight: '4.2rem',
  },
  advantagesList: {
    gap: '3rem',
    margin: 0,
    display: 'flex',
    padding: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  advantageItem: {
    gap: '1rem',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 20px -5px',
    width: '25%',
    minWidth: '25rem',
    display: 'flex',
    padding: '4rem 4rem',
    background: colors.neutral.white,
    alignItems: 'center',
    borderRadius: '2rem',
    flexDirection: 'column',
    textAlign: 'center',
    '@media(max-width: 890px)': {
      width: '80%',
      minWidth: 'auto',
    },
  },
  advantageItemTitle: {
    margin: 0,
    fontSize: '2.4rem',
    fontWeight: 700,
    lineHeight: '2.8rem',
    marginTop: '2rem',
  },
  advantageItemDescription: {
    margin: 0,
    fontSize: '1.6rem',
    textAlign: 'center',
    lineHeight: '2.2rem',
  },
  imageWrapper: {
    width: '15rem',
    height: 'auto',
  },
  demoButton: {
    margin: '8rem auto 0',
    [breakpoints.md.down]: {
      margin: '4rem auto 2rem',
    },
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
