import { createUseStyles } from 'react-jss';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import { colors } from '@insurancemenu/imenu-components-lib';
import { boxShadows } from '@insurancemenu/imenu-components-lib/dist/utils';

const styles: ComponentStylesProps<string> = {
  wrapper: {
    padding: '4rem 10rem',
    '@media(max-width: 980px)': {
      padding: '10rem 2rem 8rem',
    },
  },
  title: {
    fontSize: '2.4rem',
    lineHeight: '2.8rem',
    margin: '0',
  },
  subtitle: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
    margin: '2.5rem 0 0',
  },
  description: {
    '& p, & strong': {
      fontSize: '1.4rem',
      lineHeight: '2rem',
      margin: '1rem 0',
    },
    '& p > a': {
      margin: '0 0.5rem',
    },
  },
  link: {
    textDecoration: 'none',
    backgroundColor: colors.blue[600],
    color: colors.neutral.white,
    padding: '0.8rem 1rem',
    border: `2px solid ${colors.blue[600]}`,
    boxShadow: boxShadows.dark[4],
    textTransform: 'uppercase',
    borderRadius: 5,
    letterSpacing: '0.03rem',
    fontSize: '1.2rem',
    margin: '3rem 0 1rem',
    display: 'inline-flex',
    '&:hover': {
      color: colors.neutral[100],
      borderColor: colors.blue[500],
      backgroundColor: colors.blue[500],
    },
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
