import React from 'react';
import Contacts from 'components/contacts';
import Footer from 'components/common/Footer';
import Header from 'components/common/Header';

const ContactsPage = (): JSX.Element => {
  return (
    <div>
      <Header />
      <Contacts />
      <Footer />
    </div>
  );
};

export default ContactsPage;
