import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';

const styles: ComponentStylesProps<string> = {
  footer: { backgroundColor: colors.neutral[900] },
  footerContent: {
    maxWidth: '170rem',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2rem 10rem',
    borderBottom: `1px solid ${colors.neutral[700]}`,
    [breakpoints.lg.down]: {
      flexDirection: 'column',
    },
  },
  '@media(max-width:1200px)': {
    // MAKE IT ONLY AS FUNCTION, otherwise styles will not be applied
    footerContent: () => ({
      padding: '2rem',
    }),
  },
  copyright: {
    color: colors.neutral[200],
    fontSize: '1.4rem',
    textAlign: 'center',
    margin: 0,
    lineHeight: '2rem',
    padding: '1rem',
  },
  links: {
    display: 'flex',
    gap: '2rem',
    listStyle: 'none',
    alignItems: 'center',
    paddingLeft: 0,
    '@media(max-width:455px)': {
      flexDirection: 'column',
    },
  },
  linkItem: {
    '& a': {
      textDecoration: 'none',
      color: colors.neutral.white,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      letterSpacing: '0.02em',
    },
    '& a:hover': {
      color: colors.orange[600],
    },
  },
  imageWrapper: {
    width: '20rem',
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
