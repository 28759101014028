import React from 'react';
import Prospect from 'assets/images/features/Prospect';
import Quote from 'assets/images/features/Quote';
import { colors } from '@insurancemenu/imenu-components-lib';
import Enroll from 'assets/images/features/Enroll';
import Renew from 'assets/images/features/Renew';
import Measure from 'assets/images/features/Measure';
import { createStyles } from './styles';

const Features = (): JSX.Element => {
  const classes = createStyles();
  const FEATURES_LIST = [
    {
      title: 'Prospect',
      description: 'Grow the sales pipeline',
      icon: <Prospect height="5rem" color={colors.blue[400]} />,
    },
    {
      title: 'Quote',
      description: 'Improve broker to client touchpoints',
      icon: <Quote height="5rem" color={colors.blue[400]} />,
    },
    {
      title: 'Enroll',
      description: 'Streamline your enrollment process',
      icon: <Enroll height="5rem" color={colors.blue[400]} />,
    },
    {
      title: 'Renew',
      description: 'Take action on renewal',
      icon: <Renew height="5rem" color={colors.blue[400]} />,
    },
    {
      title: 'Measure',
      description: 'Analyze data insights to make informed decisions',
      icon: <Measure height="5rem" color={colors.blue[400]} />,
    },
  ];

  const FeaturesItems = FEATURES_LIST.map(({ title, description, icon }) => (
    <li className={classes.featureItem} key={title}>
      {icon}
      <p className={classes.featureItemTitle}>{title}</p>
      <p className={classes.featureItemDescription}>{description}</p>
    </li>
  ));
  return (
    <div className={classes.featuresSection}>
      <div className={classes.featuresInfo}>
        <h3 className={classes.featuresTitle}>HOW DOES IT WORK?</h3>
        <h4 className={classes.featuresSubtitle}>
          We provide carriers and brokers with the right set of tools to collaborate during the small business sales
          cycle -- from beginning to end.
        </h4>
      </div>
      <ul className={classes.featuresList}>{FeaturesItems}</ul>
    </div>
  );
};

export default Features;
