import React from 'react';
import AboutUs from 'components/about-us';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

const AboutUsPage = (): JSX.Element => {
  return (
    <div>
      <Header />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
