import { colors } from '@insurancemenu/imenu-components-lib';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { createUseStyles } from 'react-jss';

const styles: ComponentStylesProps<string> = {
  platformDemoSection: {
    padding: '8rem 0',
    [breakpoints.md.down]: {
      padding: '4rem 0',
    },
  },
  platformDemoTitle: {
    margin: '0 0 10rem',
    fontSize: '3rem',
    textAlign: 'center',
    lineHeight: '4.2rem',
    '@media(max-width: 900px)': {
      marginBottom: '2rem',
    },
  },
  platformDemoList: {
    gap: '8rem',
    margin: 0,
    display: 'flex',
    padding: 0,
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
    '@media(max-width: 900px)': {
      gap: '2rem',
    },
  },
  platformDemoItem: {
    padding: '0 10rem',
    gap: '2%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.neutral.white,
    '&:nth-child(2n)': {
      flexDirection: 'row-reverse',
      backgroundColor: colors.neutral[100],
      padding: '10rem',
    },
    '@media(max-width: 900px)': {
      '&, &:nth-child(2n)': {
        gap: '4rem',
        flexDirection: 'column',
        padding: '4rem',
      },
    },
    [breakpoints.sm.down]: {
      '&, &:nth-child(2n)': {
        padding: '2rem',
        gap: '2rem',
      },
    },
  },
  platformDemoItemTitle: {
    margin: 0,
    marginBottom: '2rem',
    fontSize: '3rem',
    fontWeight: 700,
    lineHeight: '3.4rem',
  },
  platformDemoItemDescription: {
    margin: 0,
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
  },
  imageWrapper: {
    width: '60%',
    '@media(max-width: 900px)': {
      width: '100%',
    },
  },
  platformDemoInfo: {
    maxWidth: '38%',
    transform: 'translateY(-2rem)',
    '@media(max-width: 900px)': {
      maxWidth: '100%',
      transform: 'none',
      textAlign: 'center',
    },
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
