import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';

const styles: ComponentStylesProps<string> = {
  ctaSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '10rem',
    color: colors.neutral.white,
    background: '#152437',
    [breakpoints.md.down]: {
      padding: '4rem',
    },
    '@media(max-width: 522px)': {
      padding: '4rem 2rem',
    },
  },
  ctaTitle: {
    margin: '0 0 1rem',
    fontSize: '3rem',
    textAlign: 'center',
    lineHeight: '4.2rem',
  },
  ctaSubtitle: {
    margin: 0,
    fontSize: '1.8rem',
    textAlign: 'center',
    lineHeight: '2.8rem',
    marginBottom: '5rem',
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
