import React from 'react';
import Header from 'components/common/Header';
import TermsOfUse from 'components/terms-of-use';

const TermsOfUsePage = (): JSX.Element => {
  return (
    <div>
      <Header showMenu={false} />
      <TermsOfUse />
    </div>
  );
};

export default TermsOfUsePage;
