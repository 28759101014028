import React from 'react';
import { ImageWrapper } from 'components/common/ImageWrapper';
import { Button } from '@insurancemenu/imenu-components-lib';
import { LINKS } from 'components/common/Header';
import { createStyles } from './styles';
import AutomateProcessImg from '../../../assets/images/advantages/automate-process.jpg';
import CompetitiveImg from '../../../assets/images/advantages/competitive.jpg';
import SalesImg from '../../../assets/images/advantages/sales.jpg';

const Advantages = (): JSX.Element => {
  const classes = createStyles();

  const ADVANTAGES_LIST = [
    {
      title: 'Automate Processes',
      description:
        'Eliminate handwritten paper forms and let EDI and auto-filled PDF group applications feed clean data into your system',
      icon: (
        <ImageWrapper imageURL={AutomateProcessImg} imageAlt="Automate Processes" className={classes.imageWrapper} />
      ),
    },
    {
      title: 'Drive New Sales',
      description:
        'Deploy web storefronts so small businesses can instantly compare plans and rates, increasing exposure to your products',
      icon: <ImageWrapper imageURL={CompetitiveImg} imageAlt="Drive New Sales" className={classes.imageWrapper} />,
    },
    {
      title: 'Stay Competitive',
      description:
        'Keep up with the competitive landscape shifting to digital distribution models and be up and running in a matter of weeks',
      icon: <ImageWrapper imageURL={SalesImg} imageAlt="Stay Competitive" className={classes.imageWrapper} />,
    },
  ];

  const AdvantagesItems = ADVANTAGES_LIST.map(({ title, description, icon }) => (
    <li className={classes.advantageItem} key={title}>
      {icon}
      <p className={classes.advantageItemTitle}>{title}</p>
      <p className={classes.advantageItemDescription}>{description}</p>
    </li>
  ));
  return (
    <div className={classes.advantagesSection}>
      <h3 className={classes.advantagesTitle}>WHY INSURANCEMENU?</h3>
      <ul className={classes.advantagesList}>{AdvantagesItems}</ul>
      <Button
        color="primary"
        size="large"
        className={classes.demoButton}
        onClick={(): void => {
          window.open(LINKS.SCHEDULE_DEMO, '_blank');
        }}
      >
        Schedule a demo
      </Button>
    </div>
  );
};

export default Advantages;
