import React from 'react';
import { Link } from 'react-router-dom';
import { LINKS } from 'components/common/Header';
import { createStyles } from './styles';

const PrivacyPolicy = (): JSX.Element => {
  const classes = createStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.description}>
        <h1 className={classes.title}>Privacy Policy</h1>
        <Link to={{ pathname: LINKS.SUPPORT }} target="_blank" className={classes.link}>
          Support Request
        </Link>
        <p>
          Thank you for using InsuranceMenu, a platform as a service or “Service” or “Website” developed for you to
          collaborate with your agent and/or an insurance or financial services provider you seek to do business with.
          We want you to know that we take your privacy very seriously and have put together this privacy policy so that
          you know why we collect the information we do and how we treat your personal information. First off, this
          policy statement governs the Website that you are currently on and the affiliated websites where you can view
          this privacy policy statement. This privacy policy does not necessarily apply to any other website you may
          have used to get here or to a link on this Website that will take you to another website (we&apos;ll discuss
          that more later on in this privacy policy). Second, this policy is NOT intended to address licensed agent or
          agency user related data (i.e. NPN or Agency contact info) which are addressed in the terms of use policy.
          This policy is for the end the “Subscriber” or “Member” and their “Dependents” who will procure, enroll,
          and/or manage their policy via InsuranceMenu with the insurance and/or financial service providers offering
          their products and policies available on InsuranceMenu.
        </p>
        <p>Users must choose to consent with our privacy policy upon creating their account.</p>
        <p>
          When you as the Subscriber use InsuranceMenu you may have to share certain personal information about
          yourself, family members, and/or your employees to do things like get a quote or to complete an application
          for an insurance carrier to get coverage via their products. This information may be entered by you or by an
          authorized person such as an agent employed by you or your employer. Examples of personal information include
          things like your name, age, address, family members, the products you selected, and contact info. In some
          cases you may have to include some information about your health and lifestyle. Things that DO NOT constitute
          personal information is any publicly available information about the company you work for and your agent who
          may be collecting this information on your behalf. Users are responsible for providing accurate information in
          order to use services on the InsuranceMenu platform.
        </p>
        <p>
          It is very important to define what “personally identifiable information” means. This means that personal
          information (like mentioned above) can be linked back to you as identified by your name or other unique
          identifier (i.e. email address or SSN). We&apos;ll refer to personally identifiable information from here on
          out as “PII”.
        </p>
        <p>
          We do not share, exchange, or sell your PII to ANYONE other than for facilitating the transactions YOU and/or
          your YOUR Agent seek to complete on InsuranceMenu. This means we use your information to complete the
          transactions YOU, or an agency authorized to access your information by YOU or your Employer, or an insurance
          or financial services provider YOU may be working with or seek to work with has initiated. The types of
          transactions we facilitate include getting a quote, enrolling in coverage, submitting paperwork, getting
          notified of a policy renewal, managing coverage, etc. This list is not exhaustive but is meant to provide easy
          to understand examples of the kinds of transactions you can do on InsuranceMenu. Occasionally, we may have to
          access your PII to provide support or to help you (directly or via agency/service provider) execute a
          transaction on InsuranceMenu. Again, we&apos;ll only use this PII to the extent we need to in order to provide
          support. InsuranceMenu is NOT a data broker. We are NOT in the business of collecting information about you to
          sell to other parties you are not actively seeking to transact with. Our primary business is to facilitate
          transactions between You, your Employer (when it comes to group benefits), an authorized agent to manage your
          PII, and an insurance or financial services provider you seek to work with. Any exceptions to this last
          statement will be outlined below.
        </p>
        <h2 className={classes.subtitle}>Your Profile on InsuranceMenu</h2>
        <p>
          In order to provide you with as accurate of a quote as possible, we may have to ask you to provide PII that
          will impact the rates or premium you have to pay if you choose to enroll in coverage with a carrier on
          InsuranceMenu. Most likely, for your security and convenience you&apos;ll have to have a profile saved on
          InsuranceMenu. When you register on our site, you will need to provide us with an email address. At this point
          we begin collecting personal information from you. You will have to choose a password that we strongly
          recommend you NEVER share with anyone. You will have to create a profile before you execute a transaction on
          InsuranceMenu. This is so that you can securely sign out and sign back in to execute any transaction you may
          have started or contemplated doing on InsuranceMenu. If you submit an application for a product available on
          InsuranceMenu, then we will share the required PII to the offering Service Provider to process your
          application or enrollment in their product or offering. If you submit an application for an insurance product
          offered through an authorized agency, then either they will enter your PII into InsuranceMenu or if you
          entered it in - they may have access to it in order to facilitate the transactions you seek to execute. Only
          the authorized agency will have access to your PII. That authorization can only come from you or your employer
          (in the case of group benefits). If you would like to sign up for a product made available through one of our
          eStores or publicly available storefronts, we may need PII to service that request. If we are acting as a
          third party administrator, we will use your personal information to provide you with the Service. In all of
          these instances, we&apos;ll only ask for the MINIMUM information needed by us to execute the Service or
          transactions you seek to execute.
        </p>
        <p>
          If your personal information changes, you or someone authorized by you or employer can make those updates
          online depending on the arrangement we have with the insurance or financial services provider you are actively
          transacting with on InsuranceMenu. In some cases you can initiate changes on InsuranceMenu and in some cases
          you&apos;ll have to do it directly with the service provider. Additionally, you may update your name, email
          address and password by clicking on the &quot;Sign in&quot; or &quot;My Profile&quot; link on this website and
          signing in using your email address and password. You will be able to manage these fields in your account
          settings. If you forget your password or think it may have been compromised, you&apos;ll always be able to
          reset it via a forget password link on the sign in page.
        </p>
        <h2 className={classes.subtitle}>Your Password and Other Security Issues</h2>
        <p>
          The password you enter when you begin an application on our website is your private entry key into your
          application. You should NEVER share it with anyone.
        </p>
        <p>
          If you forget your password, you have the option of selecting the FORGET PASSWORD function on the log on or
          sign in page. It will send your password through email (provided that you gave us your email address as your
          user ID when you created an account on our website). We do NOT reset passwords or provide passwords over the
          phone. Nor will we ever ask you to provide us your password over the phone.
        </p>
        <h2 className={classes.subtitle}>When we use service providers</h2>
        <p>
          We may need to work with various technology service providers in order to provide you with the functionality
          to execute your transactions. Examples of these services include cloud hosting providers, payment gateways,
          etc. In all cases, your PII is always encrypted and we maintain industry standard security practices. In some
          cases, PII can be shared inadvertently with us. We will delete that information as soon as we know about it.
          We make every effort possible to discourage users from sharing PII via our help desk ticketing system or in
          email. You should never share PII outside of an authenticated session on InsuranceMenu.
        </p>
        <p>
          We may use 3rd party service providers or websites to track site statistics like Google Analytics. These
          service providers do not store or use personally identifiable information.
        </p>
        <h2 className={classes.subtitle}>Disclosure when required to</h2>
        <p>
          We may disclose or report your PII when we believe, in good faith, that the disclosure is required or
          permitted under law, for example, to cooperate with regulators or law enforcement authorities or to resolve
          consumer disputes. Again, we are not a data broker and do not resell PII.
        </p>
        <h2 className={classes.subtitle}>Other information we may need, including PHI</h2>
        <p>
          In addition to your PII, you may need to provide information about family members – if you are applying or
          buying on their behalf. Again, this information will not be used for anything other than providing you with
          the Services of this site. Some of this information can be considered Personal Health Information or PHI. This
          subset of your personally identifiable information is protected under HIPPA or Health Insurance Portability
          and Accountability Act. To learn more about HIPPA and your rights under the HIPPA privacy rule, you can go
          <a href="https://www.cdc.gov/phlp/publications/topic/hipaa.html" rel="noreferrer" target="_blank">
            here:
          </a>
          . Anytime, we handle information that can be considered PHI - we will have a Business Associates Agreement in
          place with the insurance or financial services provider you seek to transact with, with InsuranceMenu serving
          the role of the Business Associate. InsuranceMenu uses industry standard practices and technologies to prevent
          disclosure of your and your families PHI outside of the authorized users designated by you in using
          InsuranceMenu or your employer (such as their agency) to execute the transactions on InsuranceMenu.
        </p>

        <h2 className={classes.subtitle}>Contacting you</h2>
        <p>
          We may use your personal information to get in touch with you when necessary to process your application or to
          provide you with a quote. The preferred method of communication will be emails, which will be sent to you
          throughout the application process to inform you of the status of your application and to seek additional
          information as needed.
        </p>

        <h2 className={classes.subtitle}>Contacting us with concerns</h2>
        <p>
          If you ever have any concerns or have questions about our policy regarding your personal information (PII and
          PHI) you can contact us directly via this
          <a
            href="https://insurancemenu.zendesk.com/hc/en-us/requests/new?ticket_form_id=21328977795476"
            rel="noreferrer"
            target="_blank"
          >
            link
          </a>
          and submit a ticket. We will get back to you within 5 business days.
        </p>

        <h2 className={classes.subtitle}>Customer satisfaction and continuous improvement</h2>
        <p>
          We may survey your satisfaction with our service, refer our products and services to other people, and/or to
          inform you of additional products and services. You will be given the option, should we reach out to you, to
          opt out of these surveys. A link will be posted in these types of correspondence with instructions on how to
          opt out.
        </p>
        <p>
          Note that we still have to send you certain communications regarding your insurance quote, application or
          active policy even if you opt out of receiving our correspondence and/or notices of additional products and
          services. Fundamentally to execute certain transactions, we need to have a means to send you correspondence or
          updates about your policy or products/services that are of interest for you.
        </p>
        <p>
          Anonymous information is any information that does NOT personally identify you, as opposed to “personally
          identifiable information”. We may use this information to improve our service, usability of the site,
          determine what products/plans to make available, help our partners better service your needs. In the course of
          providing you with service, we may share anonymous information about our collective user base to business
          partners or other service providers. In this scope, your information will be kept anonymous and no one will be
          able to identify you.
        </p>
        <p>
          We use &quot;Cookies&quot;,&quot;Clear Gifs&quot;,&quot;Internet Protocol&quot; addresses and other monitoring
          technologies to gather anonymous information as part of a continuous improvement effort and to provide a
          better experience for you. We also will keep physical, electronic and procedural safeguards in accordance with
          industry standards.
        </p>
        <p>
          We will keep your information for as long as your account is active or as needed to provide you service. We
          will keep and use your information as necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements.
        </p>
        <p>
          The information privacy practices described in this privacy policy apply only to transactions available on the
          Service or Website. Accordingly, all of the personally identifiable information you submit to us shall be
          governed by this privacy policy. Additionally, all of the anonymous information collected from this website
          shall be governed by this privacy policy.
        </p>

        <h2 className={classes.subtitle}>Additional Information About Privacy and Security </h2>
        <p>
          Please note that once you have submitted your transaction via InsuranceMenu, You may have to follow up and
          work directly with the service provider directly or via your agency representative. This privacy policy does
          not apply to the service provider or agency. It is up to you to understand their privacy policies.
        </p>
        <p>
          We will post any updates to our information privacy practices and reserve the right to update them at any time
          without notice.
        </p>
        <p>
          If at any time you would like to have your PII removed from InsuranceMenu, please submit a ticket
          <a
            href="https://insurancemenu.zendesk.com/hc/en-us/requests/new?ticket_form_id=21328977795476"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          and we will delete your PII within 90 days. Keep in mind, deleting your PII may impact your ability to
          transact with the insurance carrier or financial services provider you are associated with. Deleting your PII
          on InsuranceMenu will not remove your PII from any party you or your employer is engaging with on
          InsuranceMenu. InsuranceMenu can only delete your PII as defined in this privacy policy on its Service or
          Website.
        </p>
        <p>
          InsuranceMenu&apos;s Privacy Officer is responsible for maintaining the company&apos;s compliance with the
          stated privacy policy. Users can contact security@insurancemenu.com or submit a ticket
          <a
            href="https://insurancemenu.zendesk.com/hc/en-us/requests/new?ticket_form_id=21328977795476"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          on our Help Desk with any questions, concerns, or complaints regarding privacy matters.
        </p>

        <h2 className={classes.subtitle}>Browsers and Internet Security</h2>
        <p>
          This site uses industry standard Secure Socket Layer (&quot;SSL&quot;) technology. SSL protects information as
          it crosses the Internet. This technology is common to many banking and financial services sites that transmit
          sensitive information. To support this technology, you need an SSL-capable browser. We recommend using a
          strong encryption, 128-bit browser. These browsers will activate SSL automatically whenever you begin shopping
          for plan on our website and when you return to our website to complete an application. We recommend the latest
          non-beta version of either Google Chrome or Mozilla Firefox.
        </p>

        <h2 className={classes.subtitle}>Links to Other Websites </h2>
        <p>
          Our website contains links to other websites. Please note that when you click on one of these links you are
          &quot;clicking&quot; to another website. We are not responsible for the information privacy practices or the
          content of such websites. We encourage you to read the privacy policies of these linked websites as their
          information privacy practices may differ from ours.
        </p>
        <p>Last Updated: 12/1/23</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
