import { createUseStyles } from 'react-jss';

const styles = {
  image: {
    width: '100%',
    height: 'auto',
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
