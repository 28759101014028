import React from 'react';
import { createStyles } from './styles';

const TermsOfUse = (): JSX.Element => {
  const classes = createStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.description}>
        <h1 className={classes.title}>Terms of Use</h1>

        <p>
          Welcome to InsuranceMenu.com and/or affiliate website (“InsuranceMenu”). We are very excited to have you use
          our “Site” and “Service”. Usage of our site is contingent on agreement of these terms and conditions. Please
          read the following carefully before using our site.
        </p>
        <p>
          BY USING THE SERVICE, YOU AGREE TO THESE TERMS AND CONDITIONS ON YOUR OWN BEHALF AND ON BEHALF OF THE “USER”
          OF THE INSURANCEMENU SITE AND SERVICE. YOU REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THE USER
          TO THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS AND DO NOT HAVE THE AUTHORITY
          AS PROVIDED HEREIN, DO NOT USE THE SERVICE, SITE, OR CREATE AN ACCOUNT.
        </p>
        <h1 className={classes.subtitle}>The USER Terms and Conditions </h1>
        <h2 className={classes.subtitle}>1. License Grant & Restrictions.</h2>
        <p>
          InsuranceMenu hereby grants The USER a non-exclusive, non-transferable, right to use the Service. All rights
          not expressly granted to The USER are reserved by InsuranceMenu and its licensors. The USER shall not (i)
          license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make
          available to any third party the Service or the Content in any way; (ii) modify or make derivative works based
          upon the Service or the Content; (iii) create Internet &quot;links&quot; to the Service or &quot;frame&quot;
          or &quot;mirror&quot; any Content; or (iv) reverse engineer or access the Service in order to (a) build a
          competitive product or service, (b) build a product or service using similar ideas, features, functions or
          graphics of the Service, or (c) copy any features, functions or graphics of the Service. User passwords cannot
          be shared or used by more than one individual User but may be reassigned from time to time to new Users who
          are replacing former Users who have terminated employment or otherwise changed job status or function and no
          longer use the Service.
        </p>
        <p>
          The USER may not: (i) interfere with or disrupt the integrity or performance of the Service or the data
          contained therein; or (ii) attempt to gain unauthorized access to the Service or its related systems or
          networks
        </p>
        <p>
          The USER may not access the Service for purposes of monitoring its availability, performance or functionality,
          or for any other benchmarking or for competitive purposes.
        </p>

        <h2 className={classes.subtitle}>2. Privacy & Security.</h2>
        <p>
          Disclosure of InsuranceMenu&apos;s privacy and security policies may be viewed at
          <a href="https://home.insurancemenu.com/privacy-policy" rel="noreferrer" target="_blank">
            https://home.insurancemenu.com/privacy-policy
          </a>
          . InsuranceMenu reserves the right to modify its privacy and security policies at its discretion from time to
          time without notice.
        </p>
        <h2 className={classes.subtitle}>3. The USER&apos;s Responsibilities</h2>
        <p>
          The USER is responsible for all activity occurring under The USER&apos;s accounts and shall abide by all
          applicable local, state, and national laws, treaties and regulations in connection with The USER&apos;s use of
          the Service, including those related to data privacy, communications, export or import of data and the
          transmission of technical, personal or other data. The USER shall: (i) notify InsuranceMenu immediately of any
          unauthorized use of any password or account or any other known or suspected breach of security; (ii) report to
          InsuranceMenu and immediately stop any copying or distribution of Content that is known or suspected by The
          USER or The USER&apos;s Users; and (iii) not impersonate another InsuranceMenu user or provide false identity
          information to gain access to or use the Service.
        </p>

        <h2 className={classes.subtitle}>4. Account Information and Data</h2>
        <p>
          InsuranceMenu does not own any data, information or material that the USER submits or enters to the Service in
          the course of using the Service. The USER, not InsuranceMenu, shall have sole responsibility for the accuracy
          and intellectual property ownership or right to use of all submitted data, and, to the extent permitted by
          applicable law, InsuranceMenu shall not be responsible or liable for the deletion, correction, destruction,
          damage, or loss of any submitted data by InsuranceMenu, the The USER or otherwise. Certain USERS will be added
          to InsuranceMenu as part of a business relationship between 2 parties seeking to transact business via
          InsuranceMenu, defined from here on as “NON-SUBSCRIBERS”. NON-SUBSCRIBERS include: employees of insurance
          carriers and/or financial services providers with products on InsuranceMenu; licensed agents approved by the
          carriers and service providers; non-licensed agency users authorized to access InsuranceMenu by an agent or
          carrier/service provider; and support staff designated by the NON-SUBSCRIBERS. InsuranceMenu is not
          responsible for the accuracy and will not be responsible for access granted to InsuranceMenu by one
          NON-SUBSCRIBER on behalf of another.
        </p>

        <h2 className={classes.subtitle}>5. Intellectual Property Ownership</h2>
        <p>
          InsuranceMenu alone shall own all right, title and interest, including all related Intellectual Property
          Rights, in and to the InsuranceMenu Technology, the Content and the Service and any suggestions, ideas,
          enhancement requests, feedback, integration components and application programming interfaces, recommendations
          or other information provided by The USER or any other party relating to the Service. In the event any such
          intellectual property rights in the InsuranceMenu Technology the Content or the Service do not fall within the
          specifically enumerated works that constitute works made for hire under applicable copyright laws or are
          deemed inventions owned by InsuranceMenu, The USER hereby irrevocably, expressly and automatically assigns all
          right, title and interest worldwide in and to such intellectual property rights to InsuranceMenu. These Terms
          and Conditions are not a sale and does not convey to The USER any rights of ownership in or related to the
          Service, the InsuranceMenu Technology or the Intellectual Property Rights owned by InsuranceMenu. The
          InsuranceMenu name, the InsuranceMenu logo, and the product names associated with the Service are trademarks
          of InsuranceMenu or third parties, and no right or license is granted to use them.
        </p>

        <h2 className={classes.subtitle}>6. E-mail Notifications</h2>
        <p>
          The USER hereby consents to receiving e-mail notifications such as but not limited to reminders that payments
          are due or to be paid with respect to an invoice or charges for a benefit, plan, or service listed on the
          Site. Such e-mail notifications will include a link to the Service and will be related to the services
          available on the InsuranceMenu site.
        </p>

        <h2 className={classes.subtitle}>7. Termination for Cause</h2>
        <p>
          Any breach of The USER&apos;s obligations under these Terms and Conditions or unauthorized use of the
          InsuranceMenu Technology or Service will be deemed a material breach of the Terms and Conditions.
          InsuranceMenu, in its sole discretion, may terminate The USER&apos;s password, account or use of the Service
          if The USER breach or otherwise fail to comply with these Terms and Conditions. In addition, InsuranceMenu may
          terminate a free account at any time in its sole discretion, including without limitation any violation of
          these Terms and Conditions. The USER agrees and acknowledges that InsuranceMenu has no obligation to retain
          the Customer Data, and may delete such Customer Data, if The USER has materially breached the Terms and
          Conditions, including but not limited to failure to pay outstanding fees, and such breach has not been cured
          within 30 days of notice of such breach.
        </p>

        <h2 className={classes.subtitle}>8. Representations & Warranties</h2>
        <p>
          The USER represents and warrants that it has the legal power and authority to enter into the Terms and
          Conditions. The USER represents and warrants that The USER has not falsely identified itself nor provided any
          false information to gain access to the Service and that The USER&apos;s billing information is correct.
        </p>

        <h2 className={classes.subtitle}>9. Refund Policy</h2>
        <p>
          As a statement of policy, the policy for processing refunds or any billing concerns will be determined by the
          insurance or financial services provider whose product or policy is being purchased through InsuranceMenu. At
          no point in using InsuranceMenu, will the USER have individually paid for access. InsuranceMenu does not
          charge for individual USER access. Hence there is no refund obligation borne by InsuranceMenu. Please be aware
          that some insurance and financial services providers will charge cancellation fees or other administrative
          fees. If payment is between you and the insurance or financial services provider, it is your responsibility to
          seek the refund in accordance with their policy. By purchasing a product or policy through this site, you are
          acknowledging that responsibility. If payment is administered by InsuranceMenu, we will honor the refund
          policy of the insurance or financial services provider whose product or service you are buying. Refunds will
          be issued in a format at the sole discretion of InsuranceMenu.
        </p>
        <p>
          This refund policy does NOT apply to enterprise clients and or sponsors of InsuranceMenu Service. Enterprise
          clients/sponsors are subject to the governing SAAS agreement in place.
        </p>

        <h2 className={classes.subtitle}>10. Disclaimer of Warranties</h2>
        <p>
          EXCEPT AS PROVIDED IN THIS AGREEMENT, INSURANCEMENU AND ITS LICENSORS MAKE NO REPRESENTATIONS, WARRANTIES, OR
          GUARANTIES . INSURANCEMENU AND ITS LICENSORS DO NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE SERVICE WILL
          BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE,
          SYSTEM OR DATA, (B) THE SERVICE WILL MEET THE USER&apos;S REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA
          WILL BE ACCURATE OR RELIABLE, OR (D) THAT THE SERVICE WIL RUN ERROR-FREE OR DEFECTS FREE OR THAT ANY SUHC
          ERRORS OR DEFECTS WILL BE CORRECTED. THE SERVICE AND ALL CONTENT IS PROVIDED TO THE USER STRICTLY ON AN
          &quot;AS IS&quot; BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY
          OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW BY INSURANCEMENU AND ITS LICENSORS. INSURANCEMENU&apos;S SERVICE MAY BE SUBJECT TO LIMITATIONS,
          DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. INSURANCEMENU IS
          NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.
        </p>

        <h2 className={classes.subtitle}>11. Limitation of Liability and Indemnification</h2>
        <p>
          In no event shall InsuranceMenu, employees, Directors, its licensors, its suppliers, content collaborators, or
          any third parties mentioned on the Site be liable for any damages (including, without limitation, incidental
          and consequential damages, personal injury/wrongful death, lost profits, or damages resulting from lost data
          or business interruption) resulting from the use of or inability to use the Site or the Content, whether based
          on warranty, contract, tort, or any other legal theory, and whether or not InsuranceMenu, its licensors, its
          suppliers, or any third parties mentioned on the Site are advised of the possibility of such damages.
          InsuranceMenu, its licensors, its suppliers, or any third parties mentioned on the Site are not liable for any
          personal injury, including death, caused by your use or misuse of the Site, Content, or information. THE USER
          ACKNOWLEDGES THAT THEY SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY.
        </p>
        <p>
          The User agrees to indemnify and hold InsuranceMenu, its subsidiaries and affiliates, and each of their
          directors, officers, agents, contractors, partners and employees, harmless from and against any loss,
          liability, claim, demand, damages, costs and expenses, including reasonable attorney&apos;s fees, arising out
          of The User’s use of the Service or the Site, conduct in connection with the Service or the Site or with other
          users of the Service or the Site, or any violation of this Agreement or of any law or the rights of any third
          party.
        </p>

        <h2 className={classes.subtitle}>12. Notice</h2>
        <p>
          InsuranceMenu may give notice by means of a general notice on the Service, electronic mail to The USER&apos;s
          e-mail address on record in InsuranceMenu&apos;s account information, or by written communication sent by
          first class mail or pre-paid post to The USER&apos;s address on record in InsuranceMenu&apos;s account
          information.
        </p>

        <h2 className={classes.subtitle}>13. Modification to Terms</h2>
        <p>
          InsuranceMenu reserves the right to modify these Terms and Conditions or its policies relating to the Service
          at any time, effective upon posting of an updated version of the Terms and Conditions at
          https://home.insurancemenu.com/terms-of-use. The USER is responsible for regularly reviewing these Terms and
          Conditions. Continued use of the Service after any such changes shall constitute The USER&apos;s consent to
          such changes.
        </p>

        <h2 className={classes.subtitle}>14. General</h2>
        <p>
          These Terms and Conditions shall be governed by Massachusetts law and controlling United States federal law,
          without regard to the choice or conflicts of law provisions of any jurisdiction, and any disputes, actions,
          claims or causes of action arising out of or in connection with the Terms and Conditions or the Service shall
          be subject to the exclusive jurisdiction of the state and federal courts located in Boston, Massachusetts.
          These Terms and Conditions may not be assigned by The USER without the prior written approval of
          InsuranceMenu. No text or information set forth on any other purchase order, preprinted form or document shall
          add to or vary the Terms and Conditions. If any provision of the Terms and Conditions is held by a court of
          competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed, as nearly as
          possible, to reflect the intentions of the invalid or unenforceable provision(s), with all other provisions
          remaining in full force and effect. No joint venture, partnership, employment, or agency relationship exists
          between The USER and InsuranceMenu as a result of this agreement or use of the Service. The failure of
          InsuranceMenu to enforce any right or provision in these Terms and Conditions shall not constitute a waiver of
          such right or provision unless acknowledged and agreed to by InsuranceMenu in writing.
        </p>
        <h2 className={classes.subtitle}>15. Digital Millennium Copyright Act</h2>
        <p>
          This policy statement lists our requirements for notice of copyright infringement and for responses to such a
          notice if you or your materials are accused.
        </p>
        <p>We use the copyright infringement procedures of the Digital Millennium Copyright Act.</p>
        <ul className={classes.copyrightList}>
          <li>
            <strong>Notice of Copyright Infringement</strong>
            <p>
              To notify us of copyright infringement, please send a written communication to our Copyright Notices
              Department, at the contact points listed below in Part C. That written communication should include the
              following:
            </p>
            <ol>
              <li>
                A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
                right that is allegedly infringed.
              </li>
              <li>
                Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works
                at a single online site are covered by a single notification, a representative list of such works at
                that site.
              </li>
              <li>
                Identification of the material that is claimed to be infringing or to be the subject of infringing
                activity and that is to be removed or access to which is to be disabled, and information reasonably
                sufficient to permit us to locate the material.
              </li>
              <li>
                Information reasonably sufficient to permit us to contact the complaining party, such as an address,
                telephone number, and, if available, an electronic mail address at which the complaining party may be
                contacted.
              </li>
              <li>
                A statement that the complaining party has a good faith belief that use of the material in the manner
                complained of is not authorized by the copyright owner, its agent, or the law.
              </li>
              <li>
                A statement that the information in the notification is accurate, and under penalty of perjury, that the
                complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly
                infringed.
              </li>
            </ol>
          </li>
          <li>
            <strong>Counter-Notice by Accused Subscriber</strong>
            <p>
              If you are a subscriber and we have taken down your materials due to suspicion of copyright infringement,
              you may dispute the alleged infringement by sending a written communication to our Copyright Notice
              Department, at the contact points listed in Part C below. That written communication should include the
              following:
            </p>
            <ol>
              <li>A physical or electronic signature of the subscriber.</li>
              <li>
                Identification of the material that has been removed or to which access has been disabled and the
                location at which the material appeared before it was removed or access to it was disabled.
              </li>
              <li>
                A statement under penalty of perjury that the subscriber has a good faith belief that the material was
                removed or disabled as a result of mistake or misidentification of the material to be removed or
                disabled.
              </li>
              <li>
                The subscriber’s name, address, and telephone number, and a statement that the subscriber consents to
                the jurisdiction of Federal District Court for the judicial district in which such address is located,
                or if the subscriber’s address is outside of the United States, the Federal District Court for
                Massachusetts, and that the subscriber will accept service of process from the person who provided
                notification of copyright infringement or an agent of such person.
              </li>
            </ol>
          </li>
          <li>
            <strong>Agent for Notices</strong>
            <p>
              Please send all notices required by this policy to our Copyright Notice Department at 45 Dan Road, Canton
              MA 02021, attn: Copyright Notice Department, 617.600.4720.
            </p>
          </li>
          <li>
            <strong>Termination of Repeat Infringers</strong>
            <p>
              In appropriate circumstances, we will terminate the accounts of subscribers who are repeat copyright
              infringers.
            </p>
          </li>
          <li>
            <strong>Revision of Policy</strong>
            <p>We may revise this policy at any time, including by posting a new version at this website.</p>
          </li>
        </ul>

        <h2 className={classes.subtitle}>16. Definitions</h2>
        <p>As used in these Terms and Conditions:</p>
        <p>
          &quot;Terms and Conditions&quot; means these terms and conditions, whether agreed to electronically, in
          writing or submitted online and any materials available on the InsuranceMenu website specifically incorporated
          by reference herein, as such materials, including the terms of these Terms and Conditions, may be modified by
          InsuranceMenu from time to time in its sole discretion;
        </p>
        <p>
          &quot;Content&quot; means the audio and visual information, documents, software, products and services
          contained or made available to The USER in the course of using the Service;
        </p>
        <p>
          &quot;Customer Data&quot; means any data, information or material provided or submitted by the USER or
          submitted on behalf of the User by an authorized Agent or Service Provider the User is doing business with or
          knowingly is considering doing business with, in the course of using the Service for the intended purpose of
          the Site;
        </p>
        <p>
          &quot;Intellectual Property Rights&quot; means unpatented inventions, patent applications, patents, design
          rights, copyrights, trademarks, service marks, trade names, domain name rights, mask work rights, know-how and
          other trade secret rights, derivative data generated by Users while using the Services, and all other
          intellectual property rights, derivatives, integration components and application programming interfaces
          thereof, and forms of protection of a similar nature anywhere in the world;
        </p>
        <p>
          &quot;InsuranceMenu&quot; means collectively InsuranceMenu, Inc, InsuranceMenu.com and any affiliate site
          accessible via https://InsuranceMenu.com;
        </p>
        <p>
          &quot;InsuranceMenu Technology&quot; means all of InsuranceMenu&apos;s proprietary technology (including
          software, hardware, products, processes, algorithms, user interfaces, know-how, techniques, designs and other
          tangible or intangible technical material or information) made available to The USER by InsuranceMenu in
          providing the Service;
        </p>
        <p>
          &quot;The USER&quot; means the customer, person, agent, employer, employee, carrier, or organization that uses
          the Site or any Service of InsuranceMenu;
        </p>
        <p>
          &quot;Service(s)&quot; includes but is not limited to browsing, quoting, enrollment, premium payment, and plan
          maintenance services, or other corporate services developed, operated, and/or maintained by InsuranceMenu,
          accessible via https://InsuranceMenu.com or another designated web site or IP address, or ancillary online or
          offline products and services provided to The USER by InsuranceMenu, to which The USER are being granted
          access under these Terms and Conditions, including the InsuranceMenu Technology and the Content;
        </p>

        <h2 className={classes.subtitle}>Questions or Additional Information:</h2>

        <p>
          If The USER has questions regarding these Terms and Conditions or wishes to obtain additional information,
          please submit a ticket
          <a
            href="https://insurancemenu.zendesk.com/hc/en-us/requests/new?ticket_form_id=21328977795476"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </p>
        <p>Last Updated: 11/30/23</p>
      </div>
    </div>
  );
};

export default TermsOfUse;
