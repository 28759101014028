import React from 'react';
import { createStyles } from './styles';

const AboutUs = (): JSX.Element => {
  const classes = createStyles();
  return (
    <div>
      <div className={classes.aboutUsTitleWrapper}>
        <h1 className={classes.aboutUsTitle}>About us</h1>
      </div>
      <div className={classes.aboutUsDescriptionWrapper}>
        <p className={classes.aboutUsDescription}>
          InsuranceMenu was born out of an innovative legislative mandate passed in Massachusetts in 2010. A coalition
          of small business advocates and policymakers saw a real need to address rising healthcare costs for such a
          significant part of the labor force. InsuranceMenu co-founders Nabil Aidoud and Mason Harding were part of
          that team. In those years, Nabil and Mason saw first hand how valuable the local agent was to many of these
          small businesses and how the administrative burden only added to the frustration and costs of offering
          employees benefits.
        </p>
        <p className={classes.aboutUsDescription}>
          After working with 100’s of small businesses and their agents, the formative seeds of InsuranceMenu were sown.
          In 2014, Nabil and Mason started working with a leading agency and a number of progressive insurers to design
          a new way of handling benefits for small business that took the unique needs of this market and brought new
          innovative private exchange technology to enhance and empower the vital agent/employer relationship.
        </p>
      </div>
      <div className={classes.aboutUsQuoteWrapper}>
        <p className={classes.aboutUsQuote}>
          We believe when it comes to benefits, there is no such thing as SMB. You can’t treat a business of 50 with the
          same technology and service model as a business of 1,000. We’re laser focused on small businesses and the
          unique relationship they have with their agents.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
