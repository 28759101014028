import { Button } from '@insurancemenu/imenu-components-lib';
import React from 'react';
import { LINKS } from 'components/common/Header';
import { createStyles } from './styles';

const Hero = (): JSX.Element => {
  const classes = createStyles();

  return (
    <div className={classes.heroSection}>
      <div className={classes.heroInfo}>
        <h1 className={classes.heroTitle}>SAAS distribution platform for small group</h1>
        <h2 className={classes.heroSubtitle}>
          Complete end to end platform for the Health & Life segment for carriers and brokers to digitize distribution.{' '}
        </h2>
        <Button
          color="primary"
          size="large"
          className={classes.demoButton}
          onClick={(): void => {
            window.open(LINKS.SCHEDULE_DEMO, '_blank');
          }}
        >
          Schedule a demo
        </Button>
      </div>
      <div className={classes.image} />
    </div>
  );
};

export default Hero;
