import { createUseStyles } from 'react-jss';
import { colors } from '@insurancemenu/imenu-components-lib';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import HeroImage from '../../../assets/images/hero/heroImage4.jpg';

const styles: ComponentStylesProps<string> = {
  heroSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.5fr',
    gap: '2rem',
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '89vh',
    alignItems: 'center',
    padding: '4rem 0 4rem 10rem',
    background: colors.neutral[100],
    [breakpoints.lg.down]: {
      gridTemplateColumns: '1fr 1fr',
      padding: '4rem 0 4rem 4rem',
    },
    '@media(max-width: 980px)': {
      paddingTop: '10rem',
    },
    '@media(max-width: 885px)': {
      gridTemplateColumns: '1fr',
      paddingLeft: 0,
      minHeight: 'max-content',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    background: `url(${HeroImage}) no-repeat center center/cover`,
    boxShadow: 'rgba(0, 0, 0, 0.09) -10px 0px 20px -5px',
    borderRadius: '2rem 0 0 2rem',
    '@media(max-width: 885px)': {
      display: 'none',
    },
  },
  heroInfo: {
    maxWidth: '80%',
    [breakpoints.lg.down]: {
      maxWidth: '90%',
    },
    '@media(max-width: 885px)': {
      maxWidth: '60%',
      textAlign: 'center',
      margin: '0 auto',
    },
    '@media(max-width: 522px)': {
      maxWidth: '90%',
    },
  },
  demoButton: {
    '@media(max-width: 885px)': {
      margin: ' 0 auto',
    },
  },
  heroTitle: {
    fontSize: '4rem',
    lineHeight: '5.6rem',
    margin: 0,
    marginBottom: '2rem',
  },
  heroSubtitle: {
    fontSize: '1.8rem',
    margin: 0,
    lineHeight: '2.8rem',
    marginBottom: '5rem',
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
