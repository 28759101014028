import React from 'react';
import { createStyles } from './styles';

export interface ImageWrapperProps {
  imageURL: string;
  imageAlt: string;
  className?: string;
}
export const ImageWrapper = ({ imageURL, imageAlt, className }: ImageWrapperProps): JSX.Element => {
  const classes = createStyles();

  return (
    <div className={className}>
      <img className={classes.image} src={imageURL} alt={imageAlt} />
    </div>
  );
};
