import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import colors from '@insurancemenu/imenu-components-lib/dist/utils/colors';
import { createUseStyles } from 'react-jss';

// These styles are also used in the MobileDropdownElement components;
const styles: ComponentStylesProps<string> = {
  mobileDropdownButton: {
    width: '3.7rem',
    height: '3.7rem',
    border: 'none',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  menuStub: {
    width: '3.7rem',
    height: '3.7rem',
  },
  mobileDropdownButtonOpened: () => ({
    backgroundColor: colors.neutral[700],
    color: colors.neutral.white,
  }),
  mobileDropdownButtonClosed: {
    backgroundColor: colors.neutral.white,
    color: colors.neutral[700],
  },
  navDropdown: { zIndex: 3 },
  mobileDropdownOption: {
    padding: '1.3rem 1.6rem',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      backgroundColor: colors.neutral[200],
      width: '85%',
      height: '0.1rem',
      position: 'absolute',
      bottom: 0,
      left: '7.5%',
    },
    '&:last-of-type': {
      '&:after': {
        display: 'none',
      },
    },
  },
  mobileDropdownLink: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    minWidth: '7.2rem',
    lineHeight: '2.4rem',
    paddingLeft: 0,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  mobileDropdownIcon: {
    display: 'inline-block',
    width: '2.4rem',
    height: '2.4rem',
  },
  mobileDropdownLinkText: {
    marginLeft: '1.1rem',
  },
};
const createStyles = createUseStyles(styles);

export { createStyles };
