import React from 'react';
import Advantages from 'components/home/Advantages';
import CTABlock from 'components/home/CTABlock';
import Features from 'components/home/Features';
import Hero from 'components/home/Hero';
import Platform from 'components/home/Platform';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

const HomePage = (): JSX.Element => {
  return (
    <div>
      <Header />
      <Hero />
      <Features />
      <Advantages />
      <Platform />
      <CTABlock />
      <Footer />
    </div>
  );
};

export default HomePage;
