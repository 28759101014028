import { colors } from '@insurancemenu/imenu-components-lib';
import { createUseStyles } from 'react-jss';
import { breakpoints } from '@insurancemenu/imenu-components-lib/dist/utils';
import { ComponentStylesProps } from '@insurancemenu/imenu-components-lib/dist/types';
import BgImage from '../../assets/images/about-us/city.jpg';

const styles: ComponentStylesProps<string> = {
  aboutUsTitleWrapper: {
    padding: '13.5rem 2rem',
    background: `url(${BgImage}) no-repeat center center/cover`,
    '@media(max-width: 980px)': {
      padding: '14.4rem 2rem 8rem',
    },
  },
  aboutUsTitle: {
    fontSize: '4rem',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.neutral.white,
  },
  aboutUsDescriptionWrapper: {
    padding: '8rem 10rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    maxWidth: '100%',
    margin: '0 auto',
    [breakpoints.lg.up]: {
      maxWidth: '60%',
    },
    [breakpoints.md.down]: {
      padding: '4rem',
    },
    [breakpoints.sm.down]: {
      padding: '2rem',
    },
  },
  aboutUsDescription: {
    margin: 0,
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
  },
  aboutUsQuoteWrapper: {
    backgroundColor: colors.neutral[100],
    padding: '8rem 10rem 8rem 13rem',
    [breakpoints.md.down]: {
      padding: '4rem',
    },
    [breakpoints.sm.down]: {
      padding: '2rem',
    },
  },
  aboutUsQuote: {
    fontSize: '2rem',
    lineHeight: '3rem',
    margin: 0,
    position: 'relative',
    [breakpoints.md.up]: {
      fontSize: '2.4rem',
      lineHeight: '3.4rem',
      '&::before': {
        position: 'absolute',
        content: "''",
        top: 0,
        bottom: 0,
        left: '-4rem',
        width: '1.5rem',
        backgroundColor: colors.blue[500],
      },
    },
  },
};

const createStyles = createUseStyles(styles);
export { createStyles };
